
import { defineComponent, ref } from 'vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    AppButton,
  },
  props: {
    type: {
      type: Number,
      default: 1,
    },
    button: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const router = useRouter()
    const store = useStore()
    const modalType = ref('none')
    const text =
      props.type === 1 ? '送信'
        : props.type === 2 ? '申出'
          : props.type === 3 ? '情報提供' : ''

    return {
      props,
      modalType,
      text,

      open () {
        modalType.value = 'block'
      },
      close () {
        modalType.value = 'none'
      },
      quit () {
        if (props.type === 1) {
          store.dispatch('setS3UploadFileList', [])
          store.dispatch('setContentsData', {})
          store.dispatch('setThumbnail', [])
          store.dispatch('setReacquireFlag', true)
          router.push('/dms/outer')
        } else if (props.type === 2) {
          store.dispatch('setAutoCollectData', {})
          router.push('/dms/online')
        } else if (props.type === 3) {
          store.dispatch('setCollectAuthorData', {})
          router.push('/openinq/search')
        }
      },
    }
  },
})
