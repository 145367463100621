
import { defineComponent, ref } from 'vue'
import Table from '@/components/organisms/dms/common/Table.vue'

export default defineComponent({
  name: 'ConfirmFileList',
  components: {
    Table,
  },
  props: {
    fileList: {
      type: Array,
    },
  },
  setup (props) {
    const header = ref([
      { key: 'no', name: 'No', width: '15%' },
      { key: 'fileName', name: 'ファイル名', width: '75%' },
    ])
    return {
      props,
      header,
    }
  },
})
