import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-288a2e68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-navigation" }
const _hoisted_2 = { class: "list" }
const _hoisted_3 = {
  key: 0,
  class: "number"
}
const _hoisted_4 = {
  key: 1,
  class: "is-sr-only"
}
const _hoisted_5 = {
  key: 2,
  class: "is-sr-only"
}
const _hoisted_6 = {
  key: 3,
  class: "is-sr-only"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (data, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: _normalizeClass({ current: index + 1 === _ctx.pageNum, notLast: _ctx.list.length !== index + 1 })
        }, [
          (data.numFlag)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(index + 1), 1))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(data.text) + " ", 1),
          (index + 1 < _ctx.pageNum)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, "- 完了した画面"))
            : _createCommentVNode("", true),
          (index + 1 === _ctx.pageNum)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "- 現在の画面"))
            : _createCommentVNode("", true),
          (index + 1 > _ctx.pageNum)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, "- 未実施の画面"))
            : _createCommentVNode("", true)
        ], 2))
      }), 128))
    ])
  ]))
}