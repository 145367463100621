
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Table',
  components: {
  },
  props: {
    header: {
      type: Array,
    },
    className: {
      type: String,
    },
  },
  setup (props) {
    return {
      props,
    }
  },
})
