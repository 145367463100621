
import { defineComponent, PropType } from 'vue'
import { Metadata } from '@/data/dms/dmsData'
import { convertPermission } from '@/helpers/util/dmsUtils'

export default defineComponent({
  name: 'ConfirmMetadata',
  components: {
  },
  props: {
    metadataList: {
      type: Array as PropType<Metadata[]>,
    },
  },
  setup (props) {
    return {
      props,
      convertPermission,
    }
  },
})
