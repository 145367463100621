export const convertStatus = (status: string) => {
  return status === 'successful' ? '終了'
    : status === 'processing' || status === 'pending' ? '処理中'
      : '確認事項あり'
}

export const convertPermission = (permission: string) => {
  if (!permission) return ''
  if (permission.startsWith('国立国会図書館内限定公開')) {
    return '国立国会図書館内限定'
  }
  if (permission.startsWith('れきおん参加館限定')) {
    return '国立国会図書館内限定'
  }
  if (permission.startsWith('インターネット公開')) {
    return 'ログインなしで閲覧可能'
  }
  if (permission.startsWith('入手困難資料')) {
    return '送信サービスで閲覧可能'
  }
  return permission
}
