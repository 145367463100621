
import { defineComponent, computed, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import ConfirmMetadata from '@/components/organisms/dms/OuterInputConfirm/ConfirmMetadata.vue'
import ConfirmFileList from '@/components/organisms/dms/OuterInputConfirm/ConfirmFileList.vue'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import { Contents, uploadFileData } from '@/data/dms/dmsData'
import { postDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterInputConfirm',
  components: {
    ConfirmMetadata,
    ConfirmFileList,
    DssIcon,
    AppButton,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const contentsData = computed<Contents>(() => store.getters.contentsData)
    const metadataList = contentsData.value.metadataOuters || []
    const uploadFileList: uploadFileData[] = store.getters.s3UploadFileList || []
    const s3UploadFilePathList = computed(() => uploadFileList.map(file => { return file.s3UploadFilePath }))
    const reuseFlag = store.getters.reuseFlag ? store.getters.reuseFlag : false

    const resetFileUploadFlow = async () => {
      await store.dispatch('setS3UploadFileList', [])
      await store.dispatch('setContentsData', {})
      await store.dispatch('setReacquireFlag', true)
      await store.dispatch('setReuseFlag', false)
    }
    const setReacquireFlag = async (flag: boolean) => {
      await store.dispatch('setReacquireFlag', flag)
    }
    setReacquireFlag(false)

    const setContentsData = async (contentsData: Contents) => {
      await store.dispatch('setContentsData', contentsData)
    }
    const packContentsData = async () => {
      setContentsData({
        metadataOuters: metadataList,
        s3UploadFilePathList: s3UploadFilePathList.value,
        nasDirName: contentsData.value.nasDirName,
      })
    }

    onMounted(async () => {
      if (uploadFileList.length === 0 || metadataList.length === 0) {
        await router.push('/dms/outer/outputSelect')
      }
      await store.dispatch('setLoading', false)
    })
    return {
      router,
      metadataList,
      uploadFileList,
      reuseFlag,
      contentsData,

      async send () {
        await window.fetch('')
        await packContentsData()
        const url = `${BASE_URL}/outer/item/entry`
        const body = JSON.stringify(contentsData.value)

        const response = await postDataCredential(url, body, true)

        if (response.status === 200) {
          await resetFileUploadFlow()
          await setReacquireFlag(true)
          await router.push({ name: 'OuterInputCompletePage', params: { confirmed: 'true' } })
        }
      },
      async prev () {
        await window.fetch('')
        reuseFlag
          ? await router.push('/dms/outer/contentsInput')
          : await router.push('/dms/outer/metadataInput')
      },
    }
  },
})
