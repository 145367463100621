import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ebd70df6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "area-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", {
      class: _normalizeClass(["table", _ctx.props.className])
    }, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.header, (data, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: index,
              style: _normalizeStyle({ width: `${data.width}` })
            }, _toDisplayString(data.name), 5))
          }), 128))
        ])
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ]))
}