
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Breadcrumb',
  components: {
  },
  props: {
    list: {
      type: Array,
    },
    pageNum: {
      type: Number,
    },
  },
  setup () {
    return {
    }
  },
})
